import { PaymentDataV2 } from '@/modules/payment-core'
import { ALLOWED_HOSTS } from './constants/configurations'
import { Events } from './models/Events'
import { GooglePayExternal } from './GooglePayExternal'
import { GooglePay } from './GooglePay'

export const create = async (
    containerElement: HTMLElement,
    paymentData: PaymentDataV2,
    events: Events,
    token: string,
    cardBrands?: string[]
) => {
    console.log('Events while creating', events)
    if (ALLOWED_HOSTS.includes(window.location.hostname)) {
        const googlePay = new GooglePay(containerElement, paymentData, events, {token, isTest: __IS_DEV__, cardBrands})
        await googlePay.init()
        return googlePay
    } else {
        const googlePayExternal = new GooglePayExternal(containerElement, paymentData, events, {token, isTest: __IS_DEV__, cardBrands})
        await googlePayExternal.init()
        return googlePayExternal
    }
}

if (!window.DNAPayments) {
    window.DNAPayments = {}
}

window.DNAPayments.GooglePayComponent = {
    create
}
