import { apiCheck, FlowResult, get, post, noThrow, check } from '@/modules/back-connector'
import { ClientConfig } from "../models/ClientConfig";
import { getAPIUrl, getCryptoCardData } from "./getters";
import { CardScheme } from "../models/CardPayment/CardScheme";
import { CryptoPaymentDataV1 } from "../models/CardPayment/CryptoPaymentDataV1";
import { CryptoPaymentDataV2 } from "../models/CardPayment/CryptoPaymentDataV2";
import { PaymentResultV1 } from "../models/CardPayment/PaymentResultV1";
import { PaymentResultV2 } from "../models/CardPayment/PaymentResultV2";

export async function fetchConfiguration(token, isTest): Promise<FlowResult<ClientConfig>> {
    return noThrow(apiCheck(get(`${ getAPIUrl(isTest)  }/payments/form/configuration`, null, [['Authorization', 'Bearer ' + token]])))
}

export async function fetchCardScheme (token: string, isTest: boolean, cryptoKey: string, cardNumber: string) {
    return noThrow(apiCheck(post<CardScheme>(`${ getAPIUrl(isTest) }/transaction/iinLookup`, { packet: getCryptoCardData(cryptoKey, { pan: cardNumber } )  }, [['Authorization', 'Bearer ' + token]])))
}

export async function cryptoPayV1(token: string, isTest: boolean, data: CryptoPaymentDataV1) {
    const cryptoUrl = `${ getAPIUrl(isTest) }/payment/cryptopay`;
    return check(await noThrow(post<PaymentResultV1>(cryptoUrl, data, [['Authorization', 'Bearer ' + token]])))
}

export async function cryptoPayV2(token: string, isTest: boolean, data: CryptoPaymentDataV2) {
    const cryptoUrl = `${ getAPIUrl(isTest) }/v2/payments`;
    return check(await noThrow(post<PaymentResultV2>(cryptoUrl, data, [['Authorization', 'Bearer ' + token]])))
}
