/**
 * A converter function that converts a list of card scheme product names into a list of card network names
 * If the list is empty, null or undefined it returns an empty list
 * @acceptedCardSchemes - a list of card schemes
 */
import {CardScheme} from "../models/CardScheme";
import {CardNetworks} from "../models";

export function extractAllowedCardNetworks (acceptedCardSchemes: CardScheme[]) {
    // we need to find out what card networks are allowed according to the accepted card scheme products that
    // we obtain form the backend in the configuration
    return acceptedCardSchemes?.map(item => item.cardSchemeName) // get the card scheme product names
        .map(schemeName => {
            for (const cardSchemesKey of Object.keys(CardNetworks)) {
                if (CardNetworks[cardSchemesKey].find(item => schemeName.toLowerCase().includes(item))) {
                    return cardSchemesKey
                }
            }
            return ''
        }) // convert card scheme product names into card networks (e.g. MasterCard DEBIT => MASTERCARD)
        .reduce((previousValue, currentValue) => {
            if (!currentValue || previousValue.includes(currentValue)) {
                return previousValue
            }
            return [...previousValue, currentValue]
        }, []) || [] // remove duplicates
}
