import { ThreeDSV2 } from '../modules/payment-core'

export function setAttributes(element: HTMLElement, attributes: Record<string, any>): void {
    for (const key in attributes) {
        if (attributes.hasOwnProperty(key)) {
            const value = attributes[key];

            if (value == null) {
                element.removeAttribute(key);
            } else {
                element.setAttribute(key, value);
            }
        }
    }
}

export function createFrame(attributes: Record<string, unknown> = {}): HTMLIFrameElement {
    const iframe = document.createElement('iframe')
    const config = Object.assign({}, defaultAttributes, attributes)

    if (config.style && typeof config.style !== "string") {
        Object.assign(iframe.style, config.style);
        delete config.style;
    }

    setAttributes(iframe, config);

    if (!iframe.getAttribute("id")) {
        iframe.id = iframe.name;
    }

    return iframe
}

const defaultAttributes = {
    src: "about:blank",
    frameBorder: 0,
    allowtransparency: true,
    scrolling: "no",
}

export const createModal = (content) => {
    const div = document.createElement('div')
    div.setAttribute('style',
        "position: fixed; " +
        "top: 0; left: 0; " +
        "width: 100%; height: 100%; " +
        "display: flex; " +
        "justify-content: center; " +
        "align-items: center; " +
        "z-index: 1000; " +
        "background-color: rgba(0, 0, 0, 0.4); " +
        "padding: 15px;" +
        "z-index: 9999999;")
    div.innerHTML = `
        <div style="width: 460px; max-width: 100%; height: 500px; background-color: #fff; overflow: auto;">
            ${content}
        </div>
    `
    document.body.appendChild(div)
    return () => document.body.removeChild(div)
}

export const createThreeDSModal = (data: ThreeDSV2) => {
    console.log('Creating 3DS modal')
    const { acsUrl, threeDSSessionData, creq } = data
    const frameName = `dna-payments-output_frame`
    const formId = `dna-payments-output_form`

    const hideModal = createModal(`
        <div>
            <form
                id="${formId}"
                target="${frameName}"
                method="POST"
                action="${acsUrl}"
            >
                <input type='hidden' name='creq' value='${creq}' />
                <input type='hidden' name='threeDSSessionData' value='${threeDSSessionData}' />
            </form>
        </div>
        <iframe
            name='${frameName}'
            src='${acsUrl}'
            id='${frameName}'
            style='width: 100%; 
                    height: 100%; 
                    border: none; 
                    min-height: 400px;
                    position: relative;
                    z-index: 9999999;'
        >
            <p>Your browser does not support iframes.</p>
        </iframe>
    `)

    const form = document.getElementById(formId) as HTMLFormElement

    setTimeout(() => {    
        form.submit()
    })

    return new Promise((resolve, reject) => {
        const handlePostMessage = (event: any) => {
            if (event.data?.result && event.origin && event.origin.includes('dnapayments.com')) {
                const { result, errorMessage } = event.data

                if (result === 'ok') {
                    resolve(result)
                } else if (result === 'error') {
                    reject(errorMessage)
                }
                hideModal()
                window.removeEventListener('message', handlePostMessage)
            }
        }

        window.addEventListener('message', handlePostMessage)
    })
}
