import IsReadyToPayRequest = google.payments.api.IsReadyToPayRequest;
import {Errors} from '@/constants/Errors'
import { Events, Configuration } from './models'
import {Client, CryptoCardData, PaymentDataV2} from './modules/payment-core'
import { extractAllowedCardNetworks } from "./services/converters";
import { createThreeDSModal } from './services/ui-utils';
import {
    baseCardPaymentMethod,
    baseRequest,
    cardPaymentMethod, DEFAULT_COUNTRY_CODE, DEFAULT_CURRENCY_CODE, GOOGLE_PAY_JS_SCRIPT_URL, MERCHANT_ID,
    tokenizationSpecification
} from './constants/configurations'
import {loadExternalJS} from '@/services/loaders'

export class GooglePay {

    /**
     * Google Pay client
     * @private
     */
    private paymentsClient: google.payments.api.PaymentsClient = null

    /**
     * DNA Payments client
     * @private
     */
    private client: Client

    constructor (private containerElement: HTMLElement, private paymentData: PaymentDataV2, private events: Events, private configuration: Configuration) {
        console.log('Initializing Google Pay component: ', events)
    }

    /**
     * Initializes the DNA Payments client, loads configurations and loads the Google Pay's external JS code
     */
    init = async () => {
        if (this.configuration?.token) {
            this.client = new Client(this.configuration)
            await this.client.init()    
        }

        // load Google's JS code
        loadExternalJS(GOOGLE_PAY_JS_SCRIPT_URL, this.containerElement, this.onLoad)
    }

    get allowedCardNetworks () {
        let cardBrands: string[] = []

        if (!this.client) {
            cardBrands = this.configuration?.cardBrands || ['VISA', 'MASTERCARD']
        } else {
            cardBrands = extractAllowedCardNetworks(
                this.client?.getConfiguration()?.paymentMethodsSettings?.bankCard?.acceptedCardSchemes
            )
        }

        cardBrands = cardBrands.map((c) => c.toLocaleUpperCase())
            .filter((c) => ['VISA', 'MASTERCARD'].includes(c))


        return cardBrands
    }

    public onLoad = () => {
        // create a google pay client
        this.paymentsClient = new google.payments.api.PaymentsClient(
            {environment: this.configuration.isTest ? 'TEST' : 'PRODUCTION'}
        );

        const isReadyToPayRequest: IsReadyToPayRequest = {
            allowedPaymentMethods: [baseCardPaymentMethod(this.allowedCardNetworks, this.client?.getConfiguration())],
            ...baseRequest
        };

        // check if the payment can be conducted
        this.paymentsClient.isReadyToPay(isReadyToPayRequest)
            .then((response) => {
                console.log('Is ready to pay: ', response)
                if (response.result) {
                    const button = this.paymentsClient.createButton({
                        buttonColor: 'default',
                        buttonType: 'plain',
                        buttonLocale: 'en',
                        buttonSizeMode: 'fill',
                        onClick: this.onClick
                    });
                    button.setAttribute('id', 'dna-google-pay-btn')
                    this.containerElement.appendChild(button);
                    this.events?.onLoad && this.events?.onLoad();
                } else {
                    this.onError(Errors.failedToValidateGooglePaySession)
                }
            })
            .catch((err) => {
                this.onError({...Errors.failedToValidateGooglePaySession, additionalInfo: err})
            })
    }

    public onClick = async () => {
        if (this.events?.onClick) {
            const { paymentData, token } = (await this.events.onClick() || {})
            if (paymentData) {
                this.paymentData = paymentData
            }
            if (token) {
                this.client.setToken(token)
            }
        } else {
            if (!this.paymentData) {
                return this.onError(Errors.paymentDataMissing)
            }

            if (!this.configuration?.token) {
                return this.onError(Errors.tokenMissing)
            }
        }

        console.log('About to load payment data')
        this.paymentsClient.loadPaymentData(this.paymentRequest).then((paymentData) => {
            this.processPayment(paymentData)
        }).catch((err) => {
            if (err?.statusCode === 'CANCELED' && this.events?.onCancel) {
                this.events.onCancel()
                return
            }
            this.onError({...Errors.failedToAuthorizeGooglePayPayment, additionalInfo: err})
        })
    }

    public processPayment = async (paymentData: google.payments.api.PaymentData) => {
        console.log('Started processing payment')
        console.log('All events:', this.events)
        if (this.events?.onBeforeProcessPayment || this.events?.onBeforeProccessPayment) {
            const callback = this.events.onBeforeProcessPayment || this.events.onBeforeProccessPayment
            console.log('Found onBeforeProcessPayment event handler', callback)
            try {
                const { paymentData, token } = (await callback() || {})

                console.log('Result from onBeforeProcessPayment: ', paymentData, token)
                if (paymentData) {
                    this.paymentData = paymentData
                }
                if (token) {
                    this.client.setToken(token)
                }
            } catch (err) {
                console.log('Failed to process payment:', err)
                this.onError({...Errors.failedToProcessGooglePayPayment, additionalInfo: err})
            }
        }

        const cardData: CryptoCardData = {
            cardHolderName: `${this.paymentData?.customerDetails?.firstName || ''} ${ this.paymentData?.customerDetails?.lastName || ''}`.trim(),
            expiryDate: null,
            cardNumber: null,
            cvv: null
        }
        this.paymentData.paymentMethod = 'googlepay'

        console.log('About to do the payment')
        // DO THE PAYMENT HERE
        try {
            const { result } = await this.client.cryptoPayV2(this.paymentData, cardData, paymentData.paymentMethodData.tokenizationData.token)
            const { success, status, threeDS } = result || {}

            console.log('Payment result:', result)
            if (!success) {
                this.onError(Errors.failedToProcessGooglePayPayment)
            } else {
                if (status === 'requires_action') {
                    console.log('Payment requires 3D secure')
                    console.log('3DS event handler:', this.events?.onShow3DSecure)
                    await (
                        this.events?.onShow3DSecure
                            ? this.events.onShow3DSecure(threeDS)
                            : createThreeDSModal(threeDS)
                    )
                }
                this.events?.onPaymentSuccess && this.events?.onPaymentSuccess(result)
            }
        } catch(err) {
            console.log('Failed to process payment:', err)
            this.onError({...Errors.failedToProcessGooglePayPayment, additionalInfo: err})
        }
    }

    private get paymentRequest(): google.payments.api.PaymentDataRequest {
        const merchantId = this.configuration.isTest ?  this.paymentData?.paymentSettings?.terminalId : MERCHANT_ID
        const gatewayMerchantId = this.paymentData?.paymentSettings?.terminalId
        const merchantName = this.paymentData?.requestorDetails?.merchantName || this.client.getConfiguration()?.merchantName  || merchantId

        return {
            ...baseRequest,
            allowedPaymentMethods: [
                cardPaymentMethod(
                    this.allowedCardNetworks,
                    tokenizationSpecification(gatewayMerchantId),
                    this.client?.getConfiguration()
                )
            ],
            merchantInfo: {
                merchantId,
                merchantName
            },
            transactionInfo: {
                totalPriceStatus: 'FINAL',
                totalPriceLabel: 'Total',
                totalPrice: `${this.paymentData.amount}`,
                currencyCode: this.paymentData?.currency || DEFAULT_CURRENCY_CODE,
                countryCode: this.paymentData?.customerDetails?.billingAddress?.country || DEFAULT_COUNTRY_CODE
            }
        }
    }

    public onError = (error: {code: number, message: string, additionalInfo?: any}) => {
        this.events?.onError && this.events?.onError(error)
    }

}
