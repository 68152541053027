import { PaymentDataV1 } from "../models/PaymentDataV1";
import { PaymentDataV2 } from "../models/PaymentDataV2";
import { AddressInfo } from "../models/V2";
import { TransactionType } from "../models/TransactionType";

function convertNumberFieldPaymentData(fieldName, value): { [key: string]: number } {
    return value ? { [fieldName]: parseFloat(String(value)) } : {}
}

function convertStringFieldPaymentData(fieldName, value): { [key: string]: string } {
    return value ? { [fieldName]: String(value) } : {}
}

function convertFieldPaymentData(fieldName, value, type: string = 'string') {
    if (type === 'number') {
        return convertNumberFieldPaymentData(fieldName, value)
    } else if ( type === 'string') {
        return convertStringFieldPaymentData(fieldName, value)
    }

    return value ? { [fieldName]: value } : {}
}

export function convertPaymentDataTypesToV1(paymentData: PaymentDataV1 | PaymentDataV2): PaymentDataV1 {
    if ('paymentSettings' in paymentData && paymentData?.paymentSettings?.terminalId) {
        return convertPaymentDataFromV2toV1(paymentData)
    }
    return paymentData as PaymentDataV1
}

export function convertPaymentDataTypesToV2(paymentData: PaymentDataV1 | PaymentDataV2): PaymentDataV2 {
    if ('paymentSettings' in paymentData && paymentData?.paymentSettings?.terminalId) {
        return paymentData as PaymentDataV2
    }
    return convertPaymentDataFromV1toV2(paymentData)
}

function convertShippingAddress (shippingAddress: AddressInfo) {
    if (!shippingAddress) {
        return null
    }
    const { region, ...field } = shippingAddress

    return {
        ...field,
        ...region && region?.length < 4 ? { region } : {}
    }
}

export function convertPaymentDataFromV1toV2(paymentData: PaymentDataV1): PaymentDataV2 {
    const {
        transactionType,
        currency,
        invoiceId,
        terminal,
        description,
        merchantCustomData,
        entryMode,
        avsHouseMatrix,
        avsPostCodeMatrix,
        PAMatrix,
        CSCMatrix,
        postLink,
        failurePostLink,
        backLink,
        failureBackLink,
        accountId,
        accountFirstName,
        accountLastName,
        accountStreet1,
        accountStreet2,
        accountPostalCode,
        accountCity,
        phone,
        accountCountry,
        shippingAddress,
        accountEmail,
        recurringFrequency,
        recurringExpirationDate,
        periodicType,
        numberOfInstallments,
        sequenceType,
        merchantName,
        merchantNumber,
        merchantURL,
        merchantDepartmentID,
        merchantStoreID,
        visaMID,
        masterCardMID,
        amexMID,
        CVC2RC,
        paymentTo,
        taxAmount,
        deliveryType,
        ...fields
    } = paymentData

    return {
        ...fields,
        invoiceId: invoiceId ? String(invoiceId) : '',
        amount: parseFloat(String(paymentData.amount)),
        ...convertFieldPaymentData('currency', currency),
        ...(transactionType ? { transactionType: String(paymentData.transactionType).toUpperCase() as TransactionType } : {}),
        ...convertFieldPaymentData('description', description),
        ...convertFieldPaymentData('merchantCustomData', merchantCustomData),
        ...convertFieldPaymentData('entryMode', entryMode),
        paymentSettings: {
            terminalId: terminal,
            ...convertFieldPaymentData( 'avsHouseMatrix', avsHouseMatrix, 'number'),
            ...convertFieldPaymentData('avsPostCodeMatrix', avsPostCodeMatrix, 'number'),
            ...convertFieldPaymentData('cscMatrix', CSCMatrix, 'number'),
            ...convertFieldPaymentData('paMatrix', PAMatrix, 'number'),

            ...convertFieldPaymentData('returnUrl', backLink),
            ...convertFieldPaymentData('failureReturnUrl', failureBackLink),
            ...convertFieldPaymentData('callbackUrl', postLink),
            ...convertFieldPaymentData('failureCallbackUrl', failurePostLink)
        },
        customerDetails: {
            accountDetails: {
                ...convertFieldPaymentData('accountId', accountId)
            },
            billingAddress: {
                ...convertFieldPaymentData('firstName', accountFirstName),
                ...convertFieldPaymentData('lastName', accountLastName),
                ...convertFieldPaymentData('addressLine1', accountStreet1),
                ...convertFieldPaymentData('addressLine2', accountStreet2),
                ...convertFieldPaymentData('postalCode', accountPostalCode),
                ...convertFieldPaymentData('city', accountCity),
                ...convertFieldPaymentData('country', accountCountry)
            },
            deliveryDetails: {
                ...convertFieldPaymentData('deliveryAddress', convertShippingAddress(shippingAddress), 'object')
            },
            ...convertFieldPaymentData('email', accountEmail),
            ...convertFieldPaymentData('mobilePhone', phone)
        },
        periodic: {
            ...convertFieldPaymentData('recurringFrequency', recurringFrequency),
            ...convertFieldPaymentData('recurringExpirationDate', recurringExpirationDate),
            ...convertFieldPaymentData('periodicType', periodicType),
            ...convertFieldPaymentData('numberOfInstallments', numberOfInstallments),
            ...convertFieldPaymentData('sequenceType', sequenceType)
        },
        requestorDetails: {
            ...convertFieldPaymentData('merchantName', merchantName),
            ...convertFieldPaymentData('merchantNumber', merchantNumber),
            ...convertFieldPaymentData('merchantUrl', merchantURL),
            ...convertFieldPaymentData('merchantDepartmentId', merchantDepartmentID, 'number'),
            ...convertFieldPaymentData('merchantStoreId', merchantStoreID, 'number'),
            ...convertFieldPaymentData('visaMid', visaMID),
            ...convertFieldPaymentData('mastercardMid', masterCardMID),
            ...convertFieldPaymentData('amexMid', amexMID)
        },
        ...convertFieldPaymentData('CVC2RC', CVC2RC),
        ...convertFieldPaymentData('paymentTo', paymentTo),
        ...convertFieldPaymentData('taxAmount', taxAmount, 'number'),
        ...convertFieldPaymentData('deliveryType', deliveryType)

    }
}

export function convertPaymentDataFromV2toV1(paymentData: PaymentDataV2): PaymentDataV1 {
    const {
        invoiceId,
        amount,
        currency,
        transactionType,
        description,
        merchantCustomData,
        entryMode,
        paymentSettings,
        language,
        customerDetails,
        periodic,
        CVC2RC,
        paymentTo,
        requestorDetails,
        taxAmount,
        deliveryType,
        amountBreakdown,
        orderLines,
        productType,
        ...fields
    } = paymentData

    return {
        ...fields,
        invoiceId: invoiceId ? String(invoiceId) : '',
        amount: parseFloat(String(paymentData.amount)),
        ...convertFieldPaymentData('language', language),
        ...convertFieldPaymentData('description', description),
        ...convertFieldPaymentData('currency', currency),

        ...convertFieldPaymentData('terminal', paymentSettings?.terminalId),
        ...convertFieldPaymentData('postLink', paymentSettings?.callbackUrl),
        ...convertFieldPaymentData('failurePostLink', paymentSettings?.failureCallbackUrl),
        ...convertFieldPaymentData('backLink', paymentSettings?.returnUrl),
        ...convertFieldPaymentData('failureBackLink', paymentSettings?.failureReturnUrl),
        ...convertFieldPaymentData('accountId', customerDetails?.accountDetails?.accountId),
        ...convertFieldPaymentData('accountCountry', customerDetails?.billingAddress?.country),
        ...convertFieldPaymentData('accountCity', customerDetails?.billingAddress?.city),
        ...convertFieldPaymentData('accountStreet1', customerDetails?.billingAddress?.addressLine1),
        ...convertFieldPaymentData('accountStreet2', customerDetails?.billingAddress?.addressLine2),
        ...convertFieldPaymentData('accountStreet3', customerDetails?.billingAddress?.addressLine3),
        ...convertFieldPaymentData('accountEmail', customerDetails?.email),
        ...convertFieldPaymentData('accountFirstName', customerDetails?.billingAddress?.firstName),
        ...convertFieldPaymentData('accountLastName', customerDetails?.billingAddress?.lastName),
        ...convertFieldPaymentData('accountPostalCode', customerDetails?.billingAddress?.postalCode),
        ...convertFieldPaymentData('phone', customerDetails?.mobilePhone),
        ...convertFieldPaymentData('accountEmail', customerDetails?.email),

        ...convertFieldPaymentData('transactionType', transactionType),


        ...convertFieldPaymentData('recurringFrequency', periodic?.recurringFrequency),
        ...convertFieldPaymentData('recurringExpirationDate', periodic?.recurringExpirationDate),
        ...convertFieldPaymentData('periodicType', periodic?.periodicType),
        ...convertFieldPaymentData('sequenceType', periodic?.sequenceType),
        ...convertFieldPaymentData('numberOfInstallments', periodic?.numberOfInstallments),

        ...convertFieldPaymentData('entryMode', entryMode),
        ...convertFieldPaymentData('CVC2RC', CVC2RC),
        ...convertFieldPaymentData('paymentTo', paymentTo),

        ...convertFieldPaymentData('avsHouseMatrix', paymentSettings?.avsHouseMatrix, 'number'),
        ...convertFieldPaymentData('avsPostCodeMatrix', paymentSettings?.avsPostCodeMatrix, 'number'),
        ...convertFieldPaymentData('CSCMatrix', paymentSettings?.cscMatrix, 'number'),
        ...convertFieldPaymentData('PAMatrix', paymentSettings?.paMatrix, 'number'),

        ...convertFieldPaymentData('merchantName', requestorDetails?.merchantName),
        ...convertFieldPaymentData('merchantNumber', requestorDetails?.merchantNumber),
        ...convertFieldPaymentData('merchantURL', requestorDetails?.merchantUrl),


        ...convertFieldPaymentData('merchantDepartmentID', requestorDetails?.merchantDepartmentId, 'number'),
        ...convertFieldPaymentData('merchantStoreID', requestorDetails?.merchantStoreId, 'number'),

        ...convertFieldPaymentData('visaMID', requestorDetails?.visaMid),
        ...convertFieldPaymentData('masterCardMID', requestorDetails?.mastercardMid),
        ...convertFieldPaymentData('amexMID', requestorDetails?.amexMid),

        ...convertFieldPaymentData('merchantCustomData', merchantCustomData),
        ...convertFieldPaymentData('taxAmount', taxAmount, 'number'),
        ...convertFieldPaymentData('deliveryType', deliveryType),
        ...convertFieldPaymentData('amountBreakdown', amountBreakdown, 'object'),
        ...convertFieldPaymentData('orderLines', orderLines, 'object'),
        ...convertFieldPaymentData('shippingAddress', customerDetails?.deliveryDetails?.deliveryAddress, 'object'),
        ...convertFieldPaymentData('productType', productType)
    }
}
