export const loadExternalJS = (url: string, container: HTMLElement, onLoad: () => void) => {
    //check if the script has already been added to the given container
    const scripts = container.getElementsByTagName('script')
    for (let i = 0; i < scripts.length; i++) {
        if (scripts[i].getAttribute('src') === url) return
    }

    // add a new script tag to the given container
    const scriptTag = document.createElement('script');
    scriptTag.src = url;
    scriptTag.type = "text/javascript";
    scriptTag.onload = onLoad;
    container.appendChild(scriptTag);
}
