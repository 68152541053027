import { ClientConfig } from '../modules/payment-core';
import CardAuthMethod = google.payments.api.CardAuthMethod;
import PaymentMethodSpecification = google.payments.api.PaymentMethodSpecification;
import IsReadyToPayPaymentMethodSpecification = google.payments.api.IsReadyToPayPaymentMethodSpecification;

const UaParser = require('ua-parser-js')

export const SITE_HOST = 'https://' + (__IS_DEV__ ? 'test-' : '') + 'pay.dnapayments.com'
export const ALLOWED_HOSTS = [
    'localhost',
    'dnapayments.com',
    'pay.dnapayments.com',
    'test-pay.dnapayments.com',
]

export const GOOGLE_PAY_JS_SCRIPT_URL = 'https://pay.google.com/gp/p/js/pay.js'

export const MERCHANT_ID = 'BCR2DN6TZ7EJDVJW'

export const DEFAULT_CURRENCY_CODE = 'GBP'
export const DEFAULT_COUNTRY_CODE = 'GB'

export const baseRequest = {
    apiVersion: 2,
    apiVersionMinor: 0
}

const isAndroid = () => {
    const parser = new UaParser()
    return parser.getOS()?.name === 'Android'
}

const getAllowedAuthMethods = (config: ClientConfig): CardAuthMethod[] => {
    const allowedAuthMethods = config?.paymentMethodsSettings?.googlepay['allowedAuthMethods']
    if (allowedAuthMethods) {
        return isAndroid()
            ? allowedAuthMethods
            : allowedAuthMethods.filter(item => item !== 'CRYPTOGRAM_3DS')
    }

    return !__IS_DEV__ ? ["CRYPTOGRAM_3DS"] : ["PAN_ONLY"]
}

export const tokenizationSpecification = (gatewayMerchantId: string) => {
    return {
        type: 'PAYMENT_GATEWAY',
        parameters: {
            gateway: 'dnapayments',
            gatewayMerchantId
        }
    }

}

export const baseCardPaymentMethod = (allowedCardNetworks, config): IsReadyToPayPaymentMethodSpecification => {
    return {
        type: 'CARD',
        parameters: {
            allowedAuthMethods: getAllowedAuthMethods(config),
            allowedCardNetworks
        }
    }
}

export const cardPaymentMethod = (allowedCardNetworks, tokenizationSpecification, config): PaymentMethodSpecification => {
    return {
        type: 'CARD',
        parameters: {
            allowedAuthMethods: getAllowedAuthMethods(config),
            allowedCardNetworks
        },
        tokenizationSpecification
    }
}
