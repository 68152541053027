import {
    FAILED_TO_AUTHORIZE_GOOGLE_PAY_PAYMENT,
    FAILED_TO_INITIALIZE_GOOGLE_PAY_BUTTON,
    FAILED_TO_PROCESS_GOOGLE_PAY_PAYMENT,
    FAILED_TO_VALIDATE_GOOGLE_PAY_SESSION,
    NO_CARD_NETWORK_CONFIGURED,
    PAYMENT_DATA_MISSING,
    TOKEN_MISSING,
    PAYMENT_DATA_FIELD_MISSING_IN_RESULT_FROM_ON_CLICK,
    TOKEN_FIELD_MISSING_IN_RESULT_FROM_ON_CLICK
} from "./ErrorCodes";

export const Errors = {
    failedToInitializeGooglePay: {
        code: FAILED_TO_INITIALIZE_GOOGLE_PAY_BUTTON,
        message: 'Failed to initialize the Google Pay button'
    },
    noCardNetwork: {
        code: NO_CARD_NETWORK_CONFIGURED,
        message: 'There are no card networks configured for this terminal'
    },
    failedToValidateGooglePaySession: {
        code: FAILED_TO_VALIDATE_GOOGLE_PAY_SESSION,
        message: 'Failed to validate the Google Pay session'
    },
    failedToAuthorizeGooglePayPayment: {
        code: FAILED_TO_AUTHORIZE_GOOGLE_PAY_PAYMENT,
        message: 'Failed to authorize the Google Pay payment'
    },
    failedToProcessGooglePayPayment: {
        code: FAILED_TO_PROCESS_GOOGLE_PAY_PAYMENT,
        message: 'Failed to process the Google Pay payment'
    },
    paymentDataMissing: {
        code: PAYMENT_DATA_MISSING,
        message: 'The paymentData is missing'
    },
    tokenMissing: {
        code: TOKEN_MISSING,
        message: 'The token is missing'
    },
    paymentDataFieldMissingInResultFromOnClick: {
        code: PAYMENT_DATA_FIELD_MISSING_IN_RESULT_FROM_ON_CLICK,
        message: 'The paymentData field is missing in the returned object from the onClick event handler. Expected an object with the following structure {paymentData, token}'
    },
    tokenFieldMissingInResultFromOnClick: {
        code: TOKEN_FIELD_MISSING_IN_RESULT_FROM_ON_CLICK,
        message: 'The token field is missing in the returned object from the onClick event handler. Expected an object with the following structure {paymentData, token}'
    }
}
