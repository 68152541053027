export const urls = {
    // API URL
    API_URL: 'https://api.dnapayments.com',
    TEST_API_URL: 'https://test-api.dnapayments.com',
    // WEBSOCKET URL
    WS_URL: 'wss://api.dnapayments.com/ws',
    TEST_WS_URL: 'wss://test-api.dnapayments.com/ws'
}

export const CARD_EXPIRY_DATE_MAX_ELAPSED_YEAR = 10
