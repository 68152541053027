import qs from 'qs'

import {
    ApiResponse,
    Content,
    ContentType,
    Method
} from './../models'

import strict from './strict'
import { Options } from "../models/Options";
import { parseTextResult } from "./parsers";

export function jsonContent(data: any) {
    return new Content(
        'application/json',
        data === undefined ? undefined : JSON.stringify(data)
    )
}

export function pdfContent(data?: { [key: string]: string }) {
    return new Content(
        'application/pdf',
        data === undefined ? undefined : JSON.stringify(data)
    )
}

export function form(data: { [key: string]: string }) {
    return new Content(
        'application/x-www-form-urlencoded',
        data === undefined ? undefined : Object.entries(data)
            .map(([key, value]) => `${key}=${encodeURIComponent(value)}`).join('&')
    )
}

async function fetcher(method: Method, url: string, data?: ContentType, _headers: [string,string][] = []) {

    const headers: [string,string][] = _headers ? _headers :[]

    const request: RequestInit = {
        method,
        headers
    };

    if (data !== undefined) {

        const content: Content = data instanceof Content ? data : jsonContent(data)

        headers.push(['Content-Type', content.type])

        request.body = content.body
    }

    return await fetch(url, request) as Response
}

export async function send<T>(method: Method, url: string, data?: ContentType, headers: [string,string][] = [], options: Options = {}): Promise<ApiResponse<T>> {


    let response = await fetcher(method, url, data, headers)


    if (response.status === 204) return {
        status: 204
    };

    const result = typeof options?.parseResponse !== 'undefined' ?  await options.parseResponse(response) : await parseTextResult(response)

    return strict<ApiResponse<T>>({
        ...(response.ok ? { result } : { error: result }),
        status: response.status
    })
}

export function get<T>(url: string, data?: ContentType, headers: [string,string][] = [], options: Options = {}) {

    if (data !== undefined) {
        url = url + '?' + qs.stringify(data)
    }

    return send<T>('GET', url, undefined, headers, options)
}

export function post<T>(url: string, data?: ContentType, headers: [string,string][] = [], options: Options = {}) {
    return send<T>('POST', url, data, headers, options)
}

export function put<T>(url: string, data?: ContentType, headers: [string,string][] = [], options: Options = {}) {
    return send<T>('PUT', url, data, headers, options)
}

export function del<T>(url: string, data?: ContentType, headers: [string,string][] = [], options: Options = {}) {
    return send<T>('DELETE', url, data, headers, options)
}

export function patch<T>(url: string, data?: ContentType, headers: [string,string][] = [], options: Options = {}) {
    return send<T>('PATCH', url, data, headers, options)
}
