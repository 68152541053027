import { SITE_HOST } from './constants/configurations';
import { Events, Configuration } from './models'
import { PaymentDataV2 } from './modules/payment-core';
import { createFrame, createThreeDSModal } from './services/ui-utils';

export class GooglePayExternal {

    channel = Date.now().toString()
    iframe: HTMLIFrameElement = null

    constructor (
        private containerElement: HTMLElement,
        private paymentData: PaymentDataV2,
        private events: Events,
        private configuration: Configuration
    ) {
        console.log('Initializing Google Pay External component: ', events)
    }

    private get _events(): Events {
        return {
            ...(this.events || {}),
            onShow3DSecure: createThreeDSModal
        }
    }

    async init() {
        const { token, cardBrands } = this.configuration

        const queryParams = [
            'paymentData=' + btoa(JSON.stringify(this.paymentData)),
            'token=' + token,
            'channel=' + this.channel,
            'cardBrands=' + cardBrands ? btoa(JSON.stringify(cardBrands)) : '',
            'eventTypes=' + btoa(JSON.stringify(Object.keys(this._events)))
        ].join('&')

        this.iframe = createFrame({
            src: SITE_HOST + '/components/google-pay/iframe.html?' + queryParams,
            allowpaymentrequest: '',
            height: '46px',
            width: '100%',
        })

        this.containerElement.innerHTML = ''
        this.containerElement.append(this.iframe)

        window.addEventListener('message', this._onMessage);
    }

    destroy = async () => {
        window.removeEventListener('message', this._onMessage);
    }

    _onMessage = async (ev: MessageEvent<any>) => {
        const { eventType, channel, data } = ev.data


        if (this.channel === channel && this._events[eventType]) {
            console.log('Received message', ev)
            let result = null, error = null

            try {
                result = await this._events[eventType](data)
            } catch (err) {
                error = err
            }
            console.log('Event result', result)
            console.log('Event error', error)

            this.iframe?.contentWindow.postMessage({
                channel,
                eventType,
                data: result,
                error
            }, SITE_HOST)
        }
    }
}
