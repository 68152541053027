import * as creditCardType from 'credit-card-type'
import { getTypeInfo } from 'credit-card-type'
import JSEncrypt from 'jsencrypt'
import { urls } from '../constants'
import { CardInfo } from "../models/CardPayment/CardInfo";
import { BrowserDetails } from "../models/V2";
import {arrayBufferToString, encryptDataWithPublicKey, stringToArrayBuffer} from "./rsa";

export function getAPIUrl(isTest: boolean): string {
    if(isTest) {
        return 'https://test-api.dnapayments.com'
    }
    return 'https://api.dnapayments.com'
}

export function getUrls(isTest: boolean) {
    if (isTest) return {
        apiUrl: urls.TEST_API_URL,
        wsUrl: urls.TEST_WS_URL
    }
    return {
        apiUrl: urls.API_URL,
        wsUrl: urls.TEST_WS_URL
    }
}

function addCardType () {
    const union = getTypeInfo(creditCardType.types.UNIONPAY);
    creditCardType.addCard({
        ...union,
        lengths: [
            13,
            ...union.lengths
        ]
    })
}

export function getCardInfo(value) {
    addCardType(); // TODO: refactor
    const cards = creditCardType(value.replace(/[^0-9\\.]+/g, ''));
    const cardInfo = cards.length === 1 ? cards[0] : null
    return <CardInfo>cardInfo
}

export function getCryptoCardData(cryptoKey: string, dataToEncript: object): string { // todo: add int
    const encrypter = new JSEncrypt({});
    encrypter.setPublicKey(cryptoKey);
    const stringForCryptogram = JSON.stringify(dataToEncript);
    return encrypter.encrypt(stringForCryptogram) as string
}

export async function cryptoCardDataV2(cryptoKey, token: string, dataToEncript: object) { // todo: add int

    // fetch the part of the PEM string between header and footer
    const pemHeader = '-----BEGIN PUBLIC KEY-----'
    const pemFooter = '-----END PUBLIC KEY-----'
    const pemContents = cryptoKey.substring(pemHeader.length, cryptoKey.length - pemFooter.length)
    const binaryDerString = window.atob(pemContents)
    const binaryDer = stringToArrayBuffer(binaryDerString)

    const publicKey = await window.crypto.subtle.importKey(
        'spki',
        binaryDer,
        {
            name: 'RSA-OAEP',
            hash: { name: 'SHA-256' }
        },
        true,
        ['encrypt']
    )
    const enc = await encryptDataWithPublicKey(JSON.stringify(dataToEncript), publicKey, token)
    return window.btoa(arrayBufferToString(enc))
}

function getScreenColorDepth (): string {
    // If the value is not in the accepted values, it will be resolved to the first accepted value lower from the one
    const possibleOptions = [1, 4, 8, 15, 16, 24, 32, 48]
    const screenColorDepth = window && window.screen ? window.screen.colorDepth : 0
    let i = 0

    while (possibleOptions[++i] <= screenColorDepth);

    return String(possibleOptions[--i])
}

function getChallengeWindowSize () {
    const screenWidth = window && window.screen ? String(window.screen.width) : 0
    if (screenWidth > 768) {
        return '03'
    } else if (screenWidth > 425) {
        return '02'
    }
    return '01'
}

export function getBrowserInfo(): BrowserDetails {

    const screenWidth = window && window.screen ? String(window.screen.width) : ''
    const screenHeight = window && window.screen ? String(window.screen.height) : ''

    const userAgent = window && window.navigator ? window.navigator.userAgent : ''
    const javaEnabled = window && window.navigator ? navigator.javaEnabled() : false

    let language = ''
    if (window && window.navigator) {
        language = window.navigator.language
            ? window.navigator.language
            // @ts-ignore
            : window.navigator.browserLanguage // Else is for IE <+ 10
    }

    const d = new Date()
    const timeZone = String(d.getTimezoneOffset())

    const browserInfo = {
        screenWidth,
        screenHeight,
        screenColorDepth: getScreenColorDepth(),
        userAgent,
        timeZone,
        language,
        javaEnabled,
        challengeWindowSize: getChallengeWindowSize(),
        acceptHeader: 'text/html'
    }

    return browserInfo
}
