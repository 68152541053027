export const GOOGLE_PAY_GENERAL_ERROR = 1000
export const NO_CARD_NETWORK_CONFIGURED = GOOGLE_PAY_GENERAL_ERROR + 1
export const FAILED_TO_INITIALIZE_GOOGLE_PAY_BUTTON = GOOGLE_PAY_GENERAL_ERROR + 2
export const FAILED_TO_VALIDATE_GOOGLE_PAY_SESSION = GOOGLE_PAY_GENERAL_ERROR + 3
export const FAILED_TO_AUTHORIZE_GOOGLE_PAY_PAYMENT = GOOGLE_PAY_GENERAL_ERROR + 4
export const FAILED_TO_PROCESS_GOOGLE_PAY_PAYMENT = GOOGLE_PAY_GENERAL_ERROR + 5
export const PAYMENT_DATA_MISSING = GOOGLE_PAY_GENERAL_ERROR + 6
export const TOKEN_MISSING = GOOGLE_PAY_GENERAL_ERROR + 7
export const PAYMENT_DATA_FIELD_MISSING_IN_RESULT_FROM_ON_CLICK = GOOGLE_PAY_GENERAL_ERROR + 8
export const TOKEN_FIELD_MISSING_IN_RESULT_FROM_ON_CLICK = GOOGLE_PAY_GENERAL_ERROR + 9
