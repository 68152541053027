import CardValidator from "card-validator";
import { ApiResponse } from "@/modules/back-connector";
import { CARD_EXPIRY_DATE_MAX_ELAPSED_YEAR } from '../constants'
import { fetchConfiguration, fetchCardScheme, cryptoPayV1, cryptoPayV2 } from "./fetchers";
import { CardInfo } from "../models/CardPayment/CardInfo";
import { cryptoCardDataV2, getBrowserInfo, getCardInfo, getCryptoCardData } from "./getters";
import { ClientConfig } from "../models/ClientConfig";
import { CardError } from "../models/CardPayment/CardError";
import { Error } from "../models/Error";
import { CryptoCardData } from "../models/CardPayment/CryptoCardData";
import { PaymentResultV1 } from "../models/CardPayment/PaymentResultV1";
import { PaymentResultV2 } from "../models/CardPayment/PaymentResultV2";
import { CryptoPaymentDataV1 } from "../models/CardPayment/CryptoPaymentDataV1";
import { CryptoPaymentDataV2 } from "../models/CardPayment/CryptoPaymentDataV2";
import { PaymentDataV1, PaymentDataV2 } from "../models";
import { convertPaymentDataTypesToV1, convertPaymentDataTypesToV2 } from "./converters";

export class Client {
    private isTest: boolean = false;
    private token: string = null;
    private configuration: ClientConfig = null;

    private loadedCardSchemes: { [key: string]: number } = {};
    private loadCardSchema: number;
    public isCardSchemaLoading: boolean = false;

    constructor(option: { isTest?: boolean, token: string }) {
        this.isTest = option?.isTest;
        this.token = option?.token;
    }

    public async init() {
        await this.loadConfiguration()
    }

    public setTestMode(isTest: boolean) {
        this.isTest = isTest
    }

    public setToken(token: string) {
        this.token = token
    }

    public getConfiguration() {
        return this.configuration
    }

    private startCardSchemaLoading() {
        this.isCardSchemaLoading = true
    }

    private stopCardSchemaLoading() {
        this.isCardSchemaLoading = false
    }

    private getAcceptedCardSchemes() {
        return this.configuration?.paymentMethodsSettings?.bankCard?.acceptedCardSchemes
    }

    private isCardSchemeIdIncluded(cardSchemeId) {
        const acceptedCardSchemes = this.getAcceptedCardSchemes();
        return acceptedCardSchemes?.map((i) => i.cardSchemeId).includes(cardSchemeId)
    }

    public async loadConfiguration() {
        const result = await fetchConfiguration(this.token, this.isTest)
        if(result.error || !result.value) {
            throw new Error('Load configuration error')
        }

        this.configuration = result.value
    }

    public async isValidCardNumber(value: string): Promise<boolean | Error<CardError>>  {

        const numberValidation = CardValidator.number(value, { luhnValidateUnionPay: true });
        if (!numberValidation.isValid) {
            return {
                code: CardError.INVALID_CARD_NUMBER,
                message: 'Invalid card number'
            }
        }

        const acceptedCardSchemes = this.getAcceptedCardSchemes();

        if(acceptedCardSchemes) {
            const res = await this.fetchCardScheme(value);
            return res
        }

        return true
    }

    public isValidCardHolder(value: string): boolean | Error<CardError> {
        const validate = /^[a-zA-Z\s.\-']+$/
        if(!validate.test(value)) {
            return {
                code: CardError.INVALID_CARDHOLDER_NAME,
                message: 'Invalid cardholder name'
            }
        }
        return true
    }


    public isValidExpiryDate(value: string) {
        const expireDate = CardValidator.expirationDate(value, CARD_EXPIRY_DATE_MAX_ELAPSED_YEAR)
        if (!expireDate.isValid) {
            return {
                code: CardError.INVALID_EXPIRY_DATE,
                message: 'Invalid expiry date'
            }
        }
        return true
    }

    public isValidSecureCode(value: string, cardNumber: string) {
        if (value) {
            const cardInfo = this.getCardInfo(cardNumber);
            if(value.length < 3 || (cardInfo && cardInfo.code && value.length !== cardInfo.code.size)) {
                return {
                    code: CardError.INVALID_SECURE_CODE,
                    message: 'Invalid secure code'
                }
            }
            return true
        }

        if (typeof this.configuration?.CSCMandatory !== 'undefined' && !this.configuration?.CSCMandatory) {
            return true
        }
        return {
            code: CardError.INVALID_SECURE_CODE,
            message: 'Invalid secure code'
        }
    }

    public getCardInfo(value): CardInfo {
        return getCardInfo(value)
    }

    private async getCardScheme(cardNumber): Promise<number> {
        if(this.loadedCardSchemes?.[cardNumber]) {
            return this.loadedCardSchemes[cardNumber]
        }
        const result = await fetchCardScheme(this.token, this.isTest, this.configuration.cert, cardNumber);
        this.loadedCardSchemes[cardNumber] = result?.value?.cardSchemeId;
        return result?.value?.cardSchemeId
    }

    private async fetchCardScheme(cardNumber) {
        this.startCardSchemaLoading();
        const cardSchemeId = await this.getCardScheme(cardNumber);
        this.stopCardSchemaLoading();
        this.loadCardSchema = cardSchemeId;
        if (cardSchemeId && !this.isCardSchemeIdIncluded(cardSchemeId)) {
            return {
                code: CardError.NOT_SUPPORT_CARD_SCHEME,
                message: 'Not support card scheme'
            }
        }

        return true
    }

    public async cryptoPay(paymentData: PaymentDataV1 | PaymentDataV2, cardData: CryptoCardData, customCrypto?: string): Promise<ApiResponse<PaymentResultV1 | PaymentResultV2>> {
        if(this.configuration?.version === 2) {
            return this.cryptoPayV2(paymentData as PaymentDataV2, cardData, customCrypto)
        }

        return this.cryptoPayV1(paymentData, cardData, customCrypto)
    };

    public async cryptoPayV1(paymentData: PaymentDataV1, cardData: CryptoCardData, customCrypto?: string): Promise<ApiResponse<PaymentResultV1>> {

        const data = {
            ...convertPaymentDataTypesToV1(paymentData),
            name: cardData?.cardHolderName,
            сryptogram: customCrypto || this.getCryptoCardData(cardData, paymentData.terminal)
        };

        return cryptoPayV1(this.token, this.isTest, data)
    };

    public async cryptoPayV2(paymentData: PaymentDataV2, cardData: CryptoCardData, customCrypto?: string): Promise<ApiResponse<PaymentResultV2>> {
        const data = await this.getCardPaymentDataV2(
            convertPaymentDataTypesToV2(paymentData), cardData, customCrypto
        )
        const response = await cryptoPayV2(this.token, this.isTest, data)
        if (!response.result?.success) {
            return { error: { code: '00', message: 'Failed' } }
        }
        return response
    }

    getUserFullName(paymentData: PaymentDataV2) {
        const { firstName = '', lastName = '' } = paymentData?.customerDetails?.billingAddress || {}
        return [firstName, lastName].filter((n) => Boolean(n)).join(' ')
    }

    public async getCardPaymentDataV2(paymentData: PaymentDataV2, cardData, cryptogram?: string): Promise<CryptoPaymentDataV2> {

        return {
            ...paymentData,
            customerDetails: {
                ...paymentData.customerDetails,
                browserDetails: getBrowserInfo()
            },
            cardDetails: cryptogram ? {
                cryptogram,
                cardholderName: cardData.cardHolderName || this.getUserFullName(paymentData)
            } : { encryptedData: await this.getCryptoCardDataV2(paymentData, cardData) }
        }
    }

    public async getCryptoCardDataV2(paymentData, cardData: CryptoCardData) {
        const [ month, year ] = cardData?.expiryDate?.split('/')
        const dataToEncript = {
            accountNumber: cardData.cardNumber,
            expirationMonth: month,
            expirationYear: year,
            csc: cardData.cvv,
            cardholderName: cardData.cardHolderName || this.getUserFullName(paymentData),
            paymentTo: paymentData?.paymentTo
        }
        const result = await cryptoCardDataV2(this.getConfiguration()?.cert, this.token, dataToEncript)
        return result
    }


    private getCryptoCardData(cardData: CryptoCardData, terminal: string): string {
        const [ month, year ] = cardData?.expiryDate?.split('/')
        const dataToEncript = {
            hpan: cardData.cardNumber,
            expDate: month + year,
            cvc: cardData.cvv,
            terminalId: terminal
        };
        return getCryptoCardData(this.getConfiguration()?.cert, dataToEncript)
    }

}
